import axios from "axios";
const Base = process.env.REACT_APP_BASE_URL;
// let config = {
//   headers: {
//     "many-poster": "",
//     "Authorization": `Bearer ${localStorage.getItem('token')}`,
//     "Content-Type": "application/json",
//   },
// };

function getPost(data, config) {
  return axios.post(`${Base}/services/ai/post`, data, config);
}
function getImage(data, config) {
  return axios.post(`${Base}/services/ai/image`, data, config);
}
function getPoster(data, config) {
  return axios.post(`${Base}/services/ai/poster`, data, config);
}
function getSalesLetter(data, config) {
  return axios.post(`${Base}/services/ai/sales-letter`, data, config);
}
function getLogo(data, config) {
  return axios.post(`${Base}/services/ai/logo`, data, config);
}
// function getPosts2(title) {
//   return axios.get(`${Base}/posts?title=${title}`,config);
// }

function getIp() {
  return axios.get("https://api.ipify.org/");
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getPost,
  getImage,
  getIp,
  getPoster,
  getSalesLetter,
  getLogo,
};
