import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
// import { toast } from "react-toastify";
import services from "../../services";
import { useAuth } from "../../AuthContext";
import CustomButton from "../../components/customButton";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { toast } from "react-toastify";

const LoginPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const { login, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Required'),
    password: Yup.string()
      .required('Required'),
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  // const login = () => {
  //   if(userInfo.username === 'user' && userInfo.password === 'user') {
  //     localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9_.eyJ1c2VySWQiOiIxMjM0NTY3ODkwIiwidXNlck5hbWUiOiJ1c2VyMSJ9.987tb6OkebqtGN08bLzt8uaHgaFe8_i5RdAI4qVJgGk');
  //     navigate('/dashboard')
  //   } else {
  //     toast.error('Username or password is wrong!');
  //   }
  // }
  const onSubmit = (values) => {
    handleLogin(values)
  };
  const handleLogin = async (values) => {
    setLoading(true);
    let req = {
      email: values.email,
      password: values.password,
    };
    try {
      const response = await services.userLogin(req);
      login(response.data.data.token); // Assuming the token is in data.token
      user(JSON.stringify(response.data.data.user)); // Assuming the user
      setLoading(false);
      console.log("response=", response)
      navigate('/dashboard');
    } catch (error) {
      setLoading(false);
      console.log("error=", error)
      toast.error("Email or Password was wrong!")
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="mt-16- mb-16- flex h-full w-full items-center justify-center px-2 md:px-8 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] mx-auto w-full max-w-full flex-col items-center xl:max-w-[420px] bg-white rounded-md shadow-md p-4 md:p-8">
        <h4 className="mb-2.5 text-4xl font-bold text-neutral-700 text-center">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600 text-center">
          Enter username and password to sign in!
        </p>
        <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer ">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 ">
            Sign In with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 " />
          <p className="text-base text-gray-600 "> or </p>
          <div className="h-px w-full bg-gray-200 " />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <div className="mb-2">
                <label htmlFor="email" className="text-sm text-gray-600 font-medium">Email</label>
                <Field placeholder="Email" name="email" type="email" className="flex h-10 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200" />
                <ErrorMessage name="email" className="text-xs text-red-500" component="div" />
              </div>
              <div className="mb-2">
                <label htmlFor="password" className="text-sm text-gray-600 font-medium">Password</label>
                <div className="flex items-center relative">
                  <Field
                    name="password"
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    className="flex h-10 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-2"
                  >
                    {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                  </button>
                </div>
                <ErrorMessage name="password" className="text-xs text-red-500" component="div" />
              </div>
              <div className="mb-4 flex items-center justify-between px-2">
                <Link
                  className="text-sm font-medium text-brand-500 hover:text-brand-600 "
                  to="/forget-password"
                >
                  Forgot Password?
                </Link>
              </div>
              <CustomButton
                type="submit"
                title={'Sign In'}
                className={'rounded-md w-full'}
                isLoading={loading} />
            </Form>
          )}
        </Formik>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 ">
            Not registered yet?
          </span>
          <Link
            to="/register"
            className="ml-1 text-sm font-medium transition-all duration-200 text-green-600 hover:text-green-700 "
          >
            Create an account
          </Link>
        </div>
        <div className="mt-4">
          <Link
            to="/"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 "
          >
            Back To Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;