import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboard-layout";
import services from "../../../../services";
import { toast } from "react-toastify";
import PostersTable from "./postersTable";
// import { toast } from 'react-toastify';

const PostersPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const [loading, setLoading] = useState(false);
    const [lastPage, setLastPage] = useState();
    const [allPosters, setAllPosters] = useState([]);
    const [searchedItems, setSearchedItems] = useState();
    // const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [currentSearchedItems, setCurrentSearchedItems] = useState();
    const token = localStorage.getItem('token');
    const columnsDataPosters = [
        {
            Header: "Prompt",
            accessor: "prompt",
        },
        {
            Header: "Description",
            accessor: "description",
        },
        {
            Header: "Image",
            accessor: "image",
        },
        {
            Header: "Date",
            accessor: "order_date",
        },
        {
            Header: "Actions",
            accessor: 'id',
        },

    ];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllPosters = async () => {
        setLoading(true);
        let config = {
            headers: {
              "many-poster": '',
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          };
        try {
            const response = await services.userPosters(config);
            setLoading(false);
            setAllPosters(response.data.data);
            setLastPage(1);
            setPageSize(10);
            console.log("response=", response)
        } catch (error) {
            setLoading(false);
            console.log("error=", error)
            toast.error("Something went wrong! Please try later")
        }
    };
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    // useEffect(() => {
    //     if (currentPage !== page) {
    //         setPage(currentPage)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [currentPage]);
    useEffect(() => {
        getAllPosters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    return (
        <DashboardLayout>
            <PostersTable
                columnsData={columnsDataPosters}
                tableData={allPosters}
                lastPage={lastPage}
                currentPage={searchedItems ? searchedItems.page : 1}
                pageSize={pageSize}
                isLoading={loading}
                // currentSearches={currentSearchedItems}
                onSearchItems={(e) => {
                    console.log("search=", e)
                    setSearchedItems(e)
                }}
            />
        </DashboardLayout>
    );
};
export default PostersPage;