import React, { useMemo, useRef, useState, useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Card from "../../../../components/card";
import CustomModal from "../../components/modal";

const PostsTable = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    columnsData,
    tableData,
    currentPage,
    lastPage,
    pageSize,
    isLoading,
    currentSearches,
    onSearchItems,
    onDownloadFile,
  } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const tableRef = useRef(null);
  var tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const [searchItems, setSearchItems] = useState({
    page: 1,
    per_page: 10,
    prompt: '',
    result: '',
    from: new Date().toISOString().split('T')[0],
    to: new Date().toISOString().split('T')[0]
  })
  const [isDownloading, setIsDownloading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const tableInstance = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state,
  } = tableInstance;
  initialState.pageSize = pageSize;
  state.pageSize = pageSize;
  const [selectedRow, setSelectedRow] = useState();
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleSearch = (name, value) => {
    // console.log("handle search", name, value);
    if (name === 'page') {
      setSearchItems({ ...searchItems, [name]: value })
    } else if (name === 'per_page') {
      let temp = searchItems;
      temp.per_page = value;
      temp.page = 1;
      onSearchItems(temp);
      setSearchItems({ ...searchItems, [name]: value, page: 1 })
    } else if (name === 'from') {
      let dateTo = new Date(searchItems.to);
      let dateFrom = new Date(value);
      // let diffDays = dateTo.getDate() - dateFrom.getDate();
      const diffTime = Math.abs(dateTo - dateFrom);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log("diff from",diffDays,diffMonths,diffYears,diffTime,diffDays2)
      console.log("diff from", diffTime, diffDays)
      if (diffDays > 31 && searchItems.email === '') {
        let newToDate = new Date(dateFrom).getTime() + 2678400000;
        let dateToDay = new Date(newToDate).getDate();
        dateToDay = dateToDay < 10 ? `0${dateToDay}` : dateToDay;
        let dateToMonth = new Date(newToDate).getMonth() + 1;
        dateToMonth = dateToMonth < 10 ? `0${dateToMonth}` : dateToMonth;
        let dateToYear = new Date(newToDate).getFullYear();
        let newDateTo = dateToYear + "-" + dateToMonth + "-" + dateToDay;
        // console.log("diffDays", diffDays, dateToDay, dateToMonth, dateToYear, newDateTo);
        // console.log("diffDays=", value, newDateTo, searchItems);
        setSearchItems({ ...searchItems, to: newDateTo, from: value, page: 1 })
      } else {
        setSearchItems({ ...searchItems, [name]: value, page: 1 })
      }
      // setSearchItems({ ...searchItems, [name]: value, page: 1 })
    } else if (name === 'to') {
      let dateFrom = new Date(searchItems.from);
      let dateTo = new Date(value);
      // let diffDays = dateTo.getDate() - dateFrom.getDate();
      const diffTime = Math.abs(dateTo - dateFrom);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31 && searchItems.email === '') {
        let newFromDate = new Date(dateTo).getTime() - 2678400000;
        let dateFromDay = new Date(newFromDate).getDate();
        dateFromDay = dateFromDay < 10 ? `0${dateFromDay}` : dateFromDay;
        let dateFromMonth = new Date(newFromDate).getMonth() + 1;
        dateFromMonth = dateFromMonth < 10 ? `0${dateFromMonth}` : dateFromMonth;
        let dateFromYear = new Date(newFromDate).getFullYear();
        let newDateFrom = dateFromYear + "-" + dateFromMonth + "-" + dateFromDay;
        // console.log("diffDaysTo", diffDays, dateFromDay, dateFromMonth, dateFromYear, newDateFrom);
        // console.log("diffDaysTo=", newDateFrom, value, searchItems);
        setSearchItems({ ...searchItems, from: newDateFrom, to: value, page: 1 })
      } else {
        setSearchItems({ ...searchItems, [name]: value, page: 1 })
      }
      // setSearchItems({ ...searchItems, [name]: value, page: 1 })
    } else {
      setSearchItems({ ...searchItems, [name]: value, page: 1 })
    }
  }
  const handleShowModal = (prompt, result, date) => {
    let temp = {
      prompt: prompt,
      result: result,
      date: date
    };
    setSelectedRow(temp);
    setShowModal(true);
  }
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // console.log(searchItems);
    onSearchItems(searchItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchItems.page])
  useEffect(() => {
    if (isDownloading) {
      //onDownload();
      onDownloadFile();
      setIsDownloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloading])
  useEffect(() => {
    currentSearches && setSearchItems(currentSearches)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearches])
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <Card extra={"w-full h-full sm:overflow-auto px-2 md:px-4 2xl:px-6"}>
        <div className="bg-white rounded-md px-6- sm:overflow-auto">
          <header className="relative flex flex-col justify-between pt-4">
            <div className="text-lg text-stone-600 w-full text-left py-4 border-b border-stone-200 flex flex-col justify-between md:flex-row">
              <h2>All Posts</h2>
            </div>
          </header>
          <div className="mt-8 overflow-hidden">
            {isLoading
              ? <div role="status" className='w-full col-span-12 flex justify-center items-center min-h-[500px]'>
                <svg aria-hidden="true" className="w-20 h-20 mr-2 text-gray-200 animate-spin fill-[#49acea]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
              : data.length ? <div className="w-full overflow-x-scroll">
                <table
                  {...getTableProps()}
                  className="w-full min-w-[2000px]- 2xl:table-fixed"
                  variant="simple"
                  color="gray-500"
                  mb="24px"
                  ref={tableRef}
                >
                  <colgroup>
                    <col span="1" className="w-[20%]" />
                    <col span="1" className="w-[60%]" />
                    <col span="1" className="w-[10%]" />
                    <col span="1" className="w-[10%]" />
                  </colgroup>
                  <thead>
                    {headerGroups.map((headerGroup, index) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup.headers.map((column, index) => (
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className="border-b border-gray-200 pr-16 pb-[10px] text-start uppercase"
                            key={index}
                          >
                            <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs whitespace-nowrap">
                              {column.render("Header")}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                      let prompt = row.original && row.original.prompt;
                      let result = row.original && row.original.result;
                      let date = row.original && row.original.order_date;
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={rowIndex} className={`${rowIndex % 2 === 0 ? 'bg-gray-50' : ''} transition-all duration-200 hover:bg-gray-100`}>
                           {row.cells.map((cell, index) => {
                            let data = "";
                            if (cell.column.Header === "Prompt") {
                              data = (
                                <p className="text-xs text-left font-medium text-navy-700 px-1">
                                  {cell.value ? cell.value : <span className="text-xs font-normal text-stone-400">NULL</span>}
                                </p>
                              );
                            } else if (cell.column.Header === "Result") {
                              data = (
                                <p className="text-xs text-left font-medium text-navy-700 pr-1 lowercase overflow-hidden">
                                  {cell.value ? cell.value : <span className="text-xs font-normal text-stone-400">NULL</span>}
                                </p>
                              );
                            } else if (cell.column.Header === "Date") {
                              data = (
                                <p className="text-xs text-left font-medium text-navy-700">
                                  {cell.value}
                                </p>
                              );
                            } else if (cell.column.Header === "Actions") {
                              data = (
                                <div className="flex text-white">
                                  <button
                                    onClick={() => {
                                      handleShowModal(prompt, result, date);
                                    }}
                                    className={`bg-gray-800 hover:bg-gray-600 h-9 px-3 text-xs flex justify-center items-center mr-2 text-white rounded min-w-[100px] transition-all duration-200`}>
                                    View </button>
                                </div>
                              );
                            }
                            return (
                              <td
                                {...cell.getCellProps()}
                                key={index}
                                className="pt-[14px] pb-[16px] sm:text-[14px] text-stone-500 font-normal max-w-max- w-[150px]- max-w-[120px]-"
                              >
                                {data}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div> : <div className="w-full flex items-center justify-center text-stone-500">No Data</div>
            }
            <div className="pagination flex flex-col lg:flex-row items-start my-6 border-t border-stone-200 pt-4">
              <div className="flex items-center justify-center flex-col w-full md:flex-row basis-full xl:basis-2/4-">
                <div className="flex">
                  <button onClick={() => handleSearch('page', 1)} disabled={currentPage <= 1} className={`${currentPage <= 1 ? 'text-slate-400 ' : 'text-stone-600 hover:bg-gray-800 hover:text-white hover:border-gray-800'} border border-stone-200 rounded-md w-8 h-8 flex justify-center items-center transition-all duration-200 mr-2`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                      <path fillRule="evenodd" d="M15.79 14.77a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L11.832 10l3.938 3.71a.75.75 0 01.02 1.06zm-6 0a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L5.832 10l3.938 3.71a.75.75 0 01.02 1.06z" clipRule="evenodd" />
                    </svg>
                  </button>{' '}
                  <button onClick={() => handleSearch('page', currentPage - 1)} disabled={currentPage < 2} className={`${currentPage < 2 ? 'text-slate-400' : 'text-stone-600 hover:bg-gray-800 hover:text-white hover:border-gray-800'} border border-stone-200 rounded-md w-8 h-8 flex justify-center items-center transition-all duration-200 mr-2`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                      <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                    </svg>
                  </button>{' '}
                  <button onClick={() => handleSearch('page', currentPage + 1)} disabled={currentPage >= lastPage} className={`${currentPage >= lastPage ? 'text-slate-400' : 'text-stone-600 hover:bg-gray-800 hover:text-white hover:border-gray-800'} border border-stone-200 rounded-md w-8 h-8 flex justify-center items-center transition-all duration-200 mr-2`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                      <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                    </svg>
                  </button>{' '}
                  <button onClick={() => handleSearch('page', lastPage)} disabled={currentPage >= lastPage} className={`${currentPage >= lastPage ? 'text-slate-400' : 'text-stone-600 hover:bg-gray-800 hover:text-white hover:border-gray-800'} border border-stone-200 rounded-md w-8 h-8 flex justify-center items-center transition-all duration-200 mr-2`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                      <path fillRule="evenodd" d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                      <path fillRule="evenodd" d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
                <span className="ml-4">
                  Page{' '}
                  <strong>
                    {currentPage} of {lastPage}
                  </strong>{' '}
                </span>
                <span className="ml-2">
                  | Go to page:{' '}
                  <input
                    type="number"
                    defaultValue={currentPage}
                    className='w-[100px] border border-stone-200 rounded-md px-1 focus:outline-none'
                    onChange={e => {
                      const _page = e.target.value ? Number(e.target.value) : 0;
                      handleSearch('page', _page)
                      // onChangePage(page)
                    }}
                  />
                </span>{' '}
                <select
                  value={pageSize}
                  className='cursor-pointer focus:outline-none'
                  onChange={e => {
                    handleSearch('per_page', Number(e.target.value))
                    // onChangePageSize(Number(e.target.value))
                  }}
                >
                  {[10, 100, 500, 1000].map(pageSize => (
                    <option key={pageSize} value={pageSize} className='cursor-pointer'>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <CustomModal
        show={showModal}
        onClose={() => setShowModal(false)}
        className="md:w-2/3"
        title="Prompt"
      >
        <div className="flex justify-between items-center">
          <h2>{selectedRow ? selectedRow.prompt : ''}</h2>
          <h4>{selectedRow ? selectedRow.date : ''}</h4>
        </div>
        <p>{selectedRow ? selectedRow.result : ''}</p>
      </CustomModal>
    </>
  );

};



export default PostsTable;
