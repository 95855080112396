import React, { useState } from 'react';

const ChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handlePasswordChange = () => {
    if (newPassword !== confirmNewPassword) {
      alert('New passwords do not match!');
      return;
    }
    // You can add logic to update the password here
    alert('Password changed successfully!');
  };

  return (
    <div className="max-w-5xl items-end ">
      <h3 className=" text-lg font-bold mb-4">Change Password</h3>
      <form className="flex flex-row items-end  space-x-4">
        <div className="mb-4 md:mb-0">
          <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-600">
            Current Password:
          </label>
          <input
            type="password"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500  "
            required
          />
        </div>
        <div className="mb-4 md:mb-0">
          <label htmlFor="newPassword" className="block text-sm font-medium text-gray-600">
            New Password:
          </label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4 md:mb-0">
          <label htmlFor="confirmNewPassword" className="block text-sm font-medium text-gray-600">
            Confirm New Password:
          </label>
          <input
            type="password"
            id="confirmNewPassword"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <button
          type="button"
          onClick={handlePasswordChange}
          className="mt-4 max-w-xs w-full py-2 px-4 border-2 border-blue-500 text-blue-500 rounded-md bg-transparent hover:bg-blue-500 hover:text-white"
        >
          Change Password
        </button>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
