import React from "react";
import { CircleLoadingIcon } from "./customIcons";

const CustomButton = ({ title, type = 'button', className, onClick, isLoading, isDisabled }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={isLoading || isDisabled}
      className={`flex justify-center items-center px-4 md:px-8 text-md text-white bg-gray-800 focus:outline-none h-12 transition-all duration-200 rounded-full hover:bg-gray-600 ${className ? className : ''} ${isDisabled ? '!cursor-not-allowed !bg-gray-500' : ''}`}
    >
      {isLoading ? (
        <>
          Processing...
          <CircleLoadingIcon className='ml-4' />
        </>
      ) : (
        `${title}`
      )}
    </button>
  );
};

export default CustomButton;
