import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import services from "../../services";
import CustomButton from "../../components/customButton";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ForgetPasswordPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Required')
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const onSubmit = (values) => {
    handleLogin(values)
  };
  const handleLogin = async (values) => {
    setLoading(true);
    let req = {
      email: values.email,
      password: values.password,
    };
    try {
      const response = await services.userLogin(req);
      toast.success("Check your Email!")
      setLoading(false);
      console.log("response=", response)
      navigate('/login');
    } catch (error) { }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="mt-16- mb-16- flex h-full w-full items-center justify-center px-2 md:px-8 lg:items-center lg:justify-start">
      <div className="mt-[10vh] mx-auto w-full max-w-full flex-col items-center xl:max-w-[420px] bg-white rounded-md shadow-md p-4 md:p-8">
        <h4 className="mb-2.5 text-4xl font-bold text-neutral-700 text-center">
          Reset Password
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600 text-center">
          Enter email to reset password!
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <div className="mb-2">
                <label htmlFor="email" className="text-sm text-gray-600 font-medium">Email</label>
                <Field placeholder="Email" name="email" type="email" className="flex h-10 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200" />
                <ErrorMessage name="email" className="text-xs text-red-500" component="div" />
              </div>
              <CustomButton
                type="submit"
                title={'Reset Password'}
                className={'rounded-md w-full'}
                isLoading={loading} />
            </Form>
          )}
        </Formik>
        <div className="mt-4">
          <Link
            to="/login"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 "
          >
            Back To Login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ForgetPasswordPage;