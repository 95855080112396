import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboard-layout";
import services from "../../../../services";
import { toast } from "react-toastify";
import SalesLetterTable from "./salesletterTable";
// import Modal from "../../components/modal";


const SalesLetterPage = () => {
    const [loading, setLoading] = useState(false);
    const [lastPage, setLastPage] = useState();
    const [allSalesLetter, setAllSalesLetter] = useState([]);
    const [searchedItems, setSearchedItems] = useState();
    const [pageSize, setPageSize] = useState(10);
    const token = localStorage.getItem('token');

    const columnsDataSalesLetter = [
        {
            Header: "Prompt",
            accessor: "prompt",
        },
        {
            Header: "Result",
            accessor: "result",
        },
        {
            Header: "Date",
            accessor: "order_date",
        },
        {
            Header: "Actions",
            accessor: 'receipt_url',
        },
    ];

    const sampleSalesLetterData = [
        {
            prompt: "Ali",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis lectus dolor, sed consectetur arcu porttitor id. Suspendisse ornare efficitur dui et venenatis. Proin ut eros nulla. Pellentesque id elementum sapien, ut rhoncus leo. Donec non vulputate metus, ut vestibulum augue. Ut mollis turpis odio, at lobortis sem convallis a. Integer ac est vitae quam suscipit egestas a sit amet libero. Duis vel ultrices est, at lobortis quam. Fusce sed dui libero. In egestas odio vitae massa mollis, sed congue erat suscipit. Duis ultrices feugiat erat eu dapibus. Suspendisse vitae sem dui. Fusce ullamcorper felis sit amet est hendrerit, sit amet iaculis ex semper.",
            order_date: "2024-01-15",
        },
        {
            prompt: "Sara",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis lectus dolor, sed consectetur arcu porttitor id. Suspendisse ornare efficitur dui et venenatis. Proin ut eros nulla. Pellentesque id elementum sapien, ut rhoncus leo. Donec non vulputate metus, ut vestibulum augue. Ut mollis turpis odio, at lobortis sem convallis a. Integer ac est vitae quam suscipit egestas a sit amet libero. Duis vel ultrices est, at lobortis quam. Fusce sed dui libero. In egestas odio vitae massa mollis, sed congue erat suscipit. Duis ultrices feugiat erat eu dapibus. Suspendisse vitae sem dui. Fusce ullamcorper felis sit amet est hendrerit, sit amet iaculis ex semper.",
            order_date: "2024-02-20",
        },
        {
            prompt: "Reza",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis lectus dolor, sed consectetur arcu porttitor id. Suspendisse ornare efficitur dui et venenatis. Proin ut eros nulla. Pellentesque id elementum sapien, ut rhoncus leo. Donec non vulputate metus, ut vestibulum augue. Ut mollis turpis odio, at lobortis sem convallis a. Integer ac est vitae quam suscipit egestas a sit amet libero. Duis vel ultrices est, at lobortis quam. Fusce sed dui libero. In egestas odio vitae massa mollis, sed congue erat suscipit. Duis ultrices feugiat erat eu dapibus. Suspendisse vitae sem dui. Fusce ullamcorper felis sit amet est hendrerit, sit amet iaculis ex semper.",
            order_date: "2024-03-05",
        },
        {
            prompt: "Nadia",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis lectus dolor, sed consectetur arcu porttitor id. Suspendisse ornare efficitur dui et venenatis. Proin ut eros nulla. Pellentesque id elementum sapien, ut rhoncus leo. Donec non vulputate metus, ut vestibulum augue. Ut mollis turpis odio, at lobortis sem convallis a. Integer ac est vitae quam suscipit egestas a sit amet libero. Duis vel ultrices est, at lobortis quam. Fusce sed dui libero. In egestas odio vitae massa mollis, sed congue erat suscipit. Duis ultrices feugiat erat eu dapibus. Suspendisse vitae sem dui. Fusce ullamcorper felis sit amet est hendrerit, sit amet iaculis ex semper.",
            order_date: "2024-03-12",
        },
        {
            prompt: "Mohammad",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis lectus dolor, sed consectetur arcu porttitor id. Suspendisse ornare efficitur dui et venenatis. Proin ut eros nulla. Pellentesque id elementum sapien, ut rhoncus leo. Donec non vulputate metus, ut vestibulum augue. Ut mollis turpis odio, at lobortis sem convallis a. Integer ac est vitae quam suscipit egestas a sit amet libero. Duis vel ultrices est, at lobortis quam. Fusce sed dui libero. In egestas odio vitae massa mollis, sed congue erat suscipit. Duis ultrices feugiat erat eu dapibus. Suspendisse vitae sem dui. Fusce ullamcorper felis sit amet est hendrerit, sit amet iaculis ex semper.",
            order_date: "2024-04-18",
        },
        {
            prompt: "Fatemeh",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis lectus dolor, sed consectetur arcu porttitor id. Suspendisse ornare efficitur dui et venenatis. Proin ut eros nulla. Pellentesque id elementum sapien, ut rhoncus leo. Donec non vulputate metus, ut vestibulum augue. Ut mollis turpis odio, at lobortis sem convallis a. Integer ac est vitae quam suscipit egestas a sit amet libero. Duis vel ultrices est, at lobortis quam. Fusce sed dui libero. In egestas odio vitae massa mollis, sed congue erat suscipit. Duis ultrices feugiat erat eu dapibus. Suspendisse vitae sem dui. Fusce ullamcorper felis sit amet est hendrerit, sit amet iaculis ex semper.",
            order_date: "2024-05-22",
        },
        {
            prompt: "Javad",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis lectus dolor, sed consectetur arcu porttitor id. Suspendisse ornare efficitur dui et venenatis. Proin ut eros nulla. Pellentesque id elementum sapien, ut rhoncus leo. Donec non vulputate metus, ut vestibulum augue. Ut mollis turpis odio, at lobortis sem convallis a. Integer ac est vitae quam suscipit egestas a sit amet libero. Duis vel ultrices est, at lobortis quam. Fusce sed dui libero. In egestas odio vitae massa mollis, sed congue erat suscipit. Duis ultrices feugiat erat eu dapibus. Suspendisse vitae sem dui. Fusce ullamcorper felis sit amet est hendrerit, sit amet iaculis ex semper.",
            order_date: "2024-06-30",
        },
        {
            prompt: "Zahra",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis lectus dolor, sed consectetur arcu porttitor id. Suspendisse ornare efficitur dui et venenatis. Proin ut eros nulla. Pellentesque id elementum sapien, ut rhoncus leo. Donec non vulputate metus, ut vestibulum augue. Ut mollis turpis odio, at lobortis sem convallis a. Integer ac est vitae quam suscipit egestas a sit amet libero. Duis vel ultrices est, at lobortis quam. Fusce sed dui libero. In egestas odio vitae massa mollis, sed congue erat suscipit. Duis ultrices feugiat erat eu dapibus. Suspendisse vitae sem dui. Fusce ullamcorper felis sit amet est hendrerit, sit amet iaculis ex semper.",
            order_date: "2024-07-15",
        }, 
        {
            prompt: "Omid",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis lectus dolor, sed consectetur arcu porttitor id. Suspendisse ornare efficitur dui et venenatis. Proin ut eros nulla. Pellentesque id elementum sapien, ut rhoncus leo. Donec non vulputate metus, ut vestibulum augue. Ut mollis turpis odio, at lobortis sem convallis a. Integer ac est vitae quam suscipit egestas a sit amet libero. Duis vel ultrices est, at lobortis quam. Fusce sed dui libero. In egestas odio vitae massa mollis, sed congue erat suscipit. Duis ultrices feugiat erat eu dapibus. Suspendisse vitae sem dui. Fusce ullamcorper felis sit amet est hendrerit, sit amet iaculis ex semper.",
            order_date: "2024-08-10",
        },
        {
            prompt: "Ladan",
            result: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis lectus dolor, sed consectetur arcu porttitor id. Suspendisse ornare efficitur dui et venenatis. Proin ut eros nulla. Pellentesque id elementum sapien, ut rhoncus leo. Donec non vulputate metus, ut vestibulum augue. Ut mollis turpis odio, at lobortis sem convallis a. Integer ac est vitae quam suscipit egestas a sit amet libero. Duis vel ultrices est, at lobortis quam. Fusce sed dui libero. In egestas odio vitae massa mollis, sed congue erat suscipit. Duis ultrices feugiat erat eu dapibus. Suspendisse vitae sem dui. Fusce ullamcorper felis sit amet est hendrerit, sit amet iaculis ex semper.",
            order_date: "2024-09-01",
        },
    ];

    const getAllSalesLetter = async () => {
        setLoading(true);
        let config = {
            headers: {
                "many-poster": '',
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };
        try {
            const response = await services.userSalesLetters(config);
            setLoading(false);
            setAllSalesLetter(response.data.data);
            setLastPage(1);
            setPageSize(10);
        } catch (error) {
            setLoading(false);
            toast.error("Something went wrong! Please try later");
        }
    };

    useEffect(() => {
        // Uncomment the next line to use the API response
        getAllSalesLetter();

        // Use sample data instead for demonstration
        setAllSalesLetter(sampleSalesLetterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DashboardLayout>
            <SalesLetterTable
                columnsData={columnsDataSalesLetter}
                tableData={allSalesLetter}
                lastPage={lastPage}
                currentPage={searchedItems ? searchedItems.page : 1}
                pageSize={pageSize}
                isLoading={loading}
                onSearchItems={(e) => {
                    setSearchedItems(e);
                }}
            />
        </DashboardLayout>

    );


};

export default SalesLetterPage;
