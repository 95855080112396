import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import services from "../../services";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomButton from "../../components/customButton";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

const RegisterPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const initialValues = {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  });
  const token = localStorage.getItem('token');
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const onSubmit = (values) => {
    console.log(values);
    register(values)
  };
  const register = async (values) => {
    setLoading(true);
    let config = {
      headers: {
        "many-poster": '',
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let req = {
      name: values.name,
      email: values.email,
      password: values.password,
      password_confirmation: values.password_confirmation,
    };
    try {
      const response = await services.userRegister(req,config);
      toast.success("Your account is created successfully!");
      setLoading(false);
      localStorage.setItem('token', response.data.data.token);
      navigate('/dashboard');
      console.log("response=", response)
    } catch (error) {
      console.log("error=", error)
      toast.error(error);
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="mt-16- mb-16- flex h-full w-full items-center justify-center px-2 md:px-8 lg:items-center lg:justify-start">
      <div className="mt-[10vh] mx-auto w-full max-w-full flex-col items-center xl:max-w-[420px] bg-white rounded-md shadow-md p-4 md:p-8">
        <h4 className="mb-2.5 text-4xl font-bold text-neutral-700 text-center">
          Sign Up
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600 text-center">
          Create an Account!
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <div className="mb-2">
                <label htmlFor="name" className="text-sm text-gray-600 font-medium">Name</label>
                <Field placeholder="Name" name="name" type="text" className="flex h-10 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200" />
                <ErrorMessage name="name" className="text-xs text-red-500" component="div" />
              </div>
              <div className="mb-2">
                <label htmlFor="email" className="text-sm text-gray-600 font-medium">Email</label>
                <Field placeholder="Email" name="email" type="email" className="flex h-10 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200" />
                <ErrorMessage name="email" className="text-xs text-red-500" component="div" />
              </div>
              <div className="mb-2">
                <label htmlFor="password" className="text-sm text-gray-600 font-medium">Password</label>
                <div className="flex items-center relative">
                  <Field
                    name="password"
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    className="flex h-10 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-2"
                  >
                    {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                  </button>
                </div>
                <ErrorMessage name="password" className="text-xs text-red-500" component="div" />
              </div>
              <div className="mb-8">
                <label htmlFor="password_confirmation" className="text-sm text-gray-600 font-medium">Confirm Password</label>
                <div className="flex items-center relative">
                  <Field
                    name="password_confirmation"
                    placeholder="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="flex h-10 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200"
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute right-2"
                  >
                    {showConfirmPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                  </button>
                </div>
                <ErrorMessage name="password_confirmation" className="text-xs text-red-500" component="div" />
              </div>
              <CustomButton
                type="submit"
                title={'Register'}
                className={'rounded-md w-full'}
                isLoading={loading} />
            </Form>
          )}
        </Formik>
        <div className="mt-4">
          <Link
            to="/login"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 "
          >
            Have you account?
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;