/* eslint-disable */
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DashboardIcon } from "../../../../components/customIcons";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { useAuth } from "../../../../AuthContext";

export function SidebarLinks(props) {
  /* ----------------------------- Start variables ---------------------------- */
  let location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { routes, isMinimize } = props;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    // console.log("activeRoute=",routeName,location)
    // if (location.pathname === '/' && routeName === 'dashboard') {
    // if (location.pathname === '/dashboard' && routeName === '/dashboard') {
    //   return true;
    // } else {
    //   return location.pathname.includes(routeName);
    // }
    if(routeName === '/dashboard') {
      return location.pathname === '/dashboard';
    } else {
      return location.pathname.includes(routeName);
    }
    // return location.pathname.includes(routeName);
  };

  const handleLogOut = () => {
    // localStorage.removeItem('token');
    // navigate('/login')
    logout();
  }

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      // console.log("createLinks",route)
      return (
        // <Link key={index} to={route.layout + "/" + route.path}>
        // <Link key={index} to={`${route.path === 'dashboard' ? '/dashboard' : "/dashboard/" + route.path}`}>
        <Link key={index} to={route.path} data-tooltip-id="my-tooltip" data-tooltip-content={route.name} onClick={(e) => {
          if (route.name === 'Log Out') {
            e.preventDefault();
            handleLogOut()
          }
        }}>
          {isMinimize && <Tooltip id="my-tooltip" />}
          <div className="relative mb-3 flex justify-center items-center hover:cursor-pointer p-2-">
            <li
              className="flex cursor-pointer items-center rounded-md p-2 hover:bg-blue-100 hover:text-blue-800 transition-all duration-400"
              key={index}
            >
              <span
                className={`${activeRoute(route.path) === true
                  ? "font-bold text-brand-500"
                  : "font-medium text-gray-600-"
                  }`}
              >
                {route.icon ? route.icon : <DashboardIcon />}{" "}
              </span>
              {!isMinimize && <p
                className={`leading-1 ml-4 flex ${activeRoute(route.path) === true
                  ? "font-bold text-navy-700"
                  : "font-medium text-gray-600"
                  }`}
              >
                {route.name}
              </p>}
            </li>
            {activeRoute(route.path) ? (
              <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500" />
            ) : null}
          </div>
        </Link>
      );
    });
  };
  /* -------------------------------------------------------------------------- */
  return createLinks(routes);
}

export default SidebarLinks;
