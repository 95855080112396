import React, { useState } from 'react';
import DashboardLayout from '../../dashboard-layout';
// import profileImage from '../../../../images/elonmusk.jpg';
import { MdEdit } from "react-icons/md";
// import { CgProfile } from "react-icons/cg";
import Modal from '../../pages/editeprofile/index';
import Tabs from '../tabprofile/index';
import ChangePasswordForm from '../Securitytab/index';
import AvatarImg from '../../../../images/profile-avatar-2.png'

const ProfileSetting = ({ onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const tabs = [
    {
      label: 'Profile Info',
      content: (
        <ProfileInfo
          userName={user.name}
          userEmail={user.email}
          setIsModalOpen={setIsModalOpen}
          onClose={onClose}
        />
      )
    },
    {
      label: 'Payments',
      content: <div>Settings Content</div>
    },
    {
      label: 'Security',
      content: <ChangePasswordForm />
    },
  ];

  return (
    <DashboardLayout>
      <div className="bg-white p-5 rounded-lg shadow-lg">
        <h2 className="text-lg font-bold mb-3">Profile Settings</h2>
        <Tabs tabs={tabs} />
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        userName={user.name}
        userEmail={user.email}
      />
    </DashboardLayout>
  );
};

const ProfileInfo = ({ userName, userEmail, setIsModalOpen, onClose }) => (
  <div className="flex gap-4">
    <div>
      <img
        src={AvatarImg}
        alt="Profile"
        className="w-32 h-32 rounded-lg"
      />
    </div>
    <form>
      <div>
        <h3 className="text-sm font-medium text-gray-600">UserName:</h3>
        <p className="text-lg font-semibold">{userName}</p>
      </div>

      <div className="mt-2">
        <h3 className="text-sm font-medium text-gray-600">Email:</h3>
        <p className="text-lg font-semibold">{userEmail}</p>
      </div>
      <div className="flex space-x-4 mt-4">
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="border-2 border-yellow-500 text-yellow-500 px-4 py-2 rounded flex items-center bg-transparent hover:bg-yellow-500 hover:text-white"
        >
          <MdEdit className="mr-2" />
          Edit Profile
        </button>

        {/* <button
          type="button"
          onClick={onClose}
          className="border-2 border-red-500 text-red-500 px-4 py-2 rounded flex items-center bg-transparent hover:bg-red-500 hover:text-white"
        >
          <CgProfile className="mr-2" />
          Cancel
        </button> */}
      </div>
    </form>
  </div>
);

export default ProfileSetting;
