import { useEffect, useRef } from 'react';
const CustomModal = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const wrapperRef = useRef(null);
    const { show, title, children, className, onClose } = props;
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onClose();
                console.log("clicked", event)
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrapperRef]);
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-stone-700 bg-opacity-40 justify-center px-4 md:px-0 items-center ${show ? 'flex' : 'hidden'}`} tabIndex={-1} aria-hidden="true">
            <div className={`bg-white px-1 md:px-2 rounded-lg shadow-sm-light hover:shadow-md cardWrp pointer-events-none relative translate-y-[-50px] opacity-100 transition-all duration-300 ease-in-out md:w-1/2 ${className ? className : ''}`}>
                <div className={`w-full flex justify-between items-center border-b border-stone-300 py-2 pl-4 pr-2`}>
                    <div className={`flex justify-between`}>
                        <h2 className='text-lg md:text-2xl text-stone-700'>{title || ''}</h2>
                    </div>
                    <div className="flex cursor-pointer" onClick={onClose}>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0761 9.50515L18.1786 2.40029C18.3867 2.19212 18.5037 1.90978 18.5037 1.61539C18.5037 1.321 18.3867 1.03866 18.1786 0.830489C17.9704 0.622322 17.688 0.505371 17.3937 0.505371C17.0993 0.505371 16.8169 0.622322 16.6088 0.830489L9.50389 7.93295L2.39901 0.830489C2.19085 0.622322 1.90851 0.505371 1.61412 0.505371C1.31973 0.505371 1.03739 0.622322 0.82922 0.830489C0.621052 1.03866 0.504109 1.321 0.504109 1.61539C0.504109 1.90978 0.621052 2.19212 0.82922 2.40029L7.9317 9.50515L0.82922 16.61C0.726017 16.713 0.644146 16.8354 0.588282 16.9701C0.532418 17.1047 0.503662 17.2491 0.503662 17.3949C0.503662 17.5407 0.532418 17.6851 0.588282 17.8198C0.644146 17.9545 0.726017 18.0768 0.82922 18.1798C0.93206 18.2833 1.05435 18.3654 1.18906 18.4215C1.32376 18.4775 1.46822 18.5064 1.61412 18.5064C1.76002 18.5064 1.90448 18.4775 2.03918 18.4215C2.17389 18.3654 2.29617 18.2833 2.39901 18.1798L9.50389 11.0774L16.6088 18.1798C16.8169 18.388 17.0993 18.5049 17.3937 18.5049C17.688 18.5049 17.9704 18.388 18.1786 18.1798C18.3867 17.9717 18.5037 17.6893 18.5037 17.3949C18.5037 17.1005 18.3867 16.8182 18.1786 16.61L11.0761 9.50515Z" fill="#B3B3B3" />
                        </svg>
                    </div>
                </div>
                <div
                    ref={wrapperRef}
                    className={`pointer-events-auto pt-2 pb-4 md:px-8 md:pt-4 md:pb-8 relative w-full border-none bg-clip-padding text-current outline-none`}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default CustomModal;
