/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// import RouteContext from './context/route';
import HomePage from './pages/home';
import DashboardPage from './pages/dashboard';
import LoginPage from './pages/login';
import RegisterPage from './pages/login/register';
import ForgetPasswordPage from './pages/login/forgetPassword';
// import { AuthProvider } from './AuthContext';
// import ProtectedRoute from './ProtectedRoute';
import { useAuth } from './AuthContext';
import PostsPage from './pages/dashboard/pages/posts';
import PostersPage from './pages/dashboard/pages/posters';
import LogosPage from './pages/dashboard/pages/logos';
import SalesLetterPage from './pages/dashboard/pages/salesletter';
import ProfileSetting from './pages/dashboard/pages/profile/index';

const AllRoutes = () => {
  const { isAuthenticated } = useAuth();
  /* ----------------------------- Start variables ---------------------------- */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(()=>{
    // console.log("isAuthenticated=",isAuthenticated)
  },[isAuthenticated])
  /* -------------------------------------------------------------------------- */
  return (
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/social-post" element={<HomePage type="social-post"/>} />
            <Route path="/poster" element={<HomePage type="poster"/>} />
            <Route path="/sales-letter" element={<HomePage type="sales-letter"/>} />
            <Route path="/logo" element={<HomePage type="logo"/>} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forget-password" element={<ForgetPasswordPage />} />
            {/* <Route path="/dashboard" element={<DashboardPage />} /> */}
            {/* <ProtectedRoute path="/dashboard" element={<DashboardPage />} /> */}
            <Route path="/dashboard" element={isAuthenticated ? <DashboardPage /> : <Navigate to="/login" replace />}/>
            <Route path="/dashboard/posts" element={isAuthenticated ? <PostsPage /> : <Navigate to="/login" replace />}/>
            <Route path="/dashboard/posters" element={isAuthenticated ? <PostersPage /> : <Navigate to="/login" replace />}/>
            <Route path="/dashboard/logos" element={isAuthenticated ? <LogosPage /> : <Navigate to="/login" replace />}/>
            <Route path="/dashboard/salesletter" element={isAuthenticated ? <SalesLetterPage /> : <Navigate to="/login" replace />}/>
            <Route path="/dashboard/profile" element={isAuthenticated ? <ProfileSetting /> : <Navigate to="/login" replace />}/>

          </Routes>
    // <AuthProvider>
    //    <Router>
    //    </Router>
    // </AuthProvider>
    // <RouteContext.Provider>
    // </RouteContext.Provider>
  );
};

export default AllRoutes;
