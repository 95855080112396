import axios from 'axios';
const Base = process.env.REACT_APP_BASE_URL;
// let config = {
//   headers: {
//     "many-poster": '',
//     "Authorization": `Bearer ${localStorage.getItem('token')}`,
//     "Content-Type": "application/json",
//   },
// };

function userRegister(data,config) {
  return axios.post(`${Base}/user/register`, data,config);
}
function userLogin(data) {
  return axios.post(`${Base}/user/login`, data);
}
function userLogout(data) {
  return axios.post(`${Base}/user/logout`, data);
}
function userPosts(config) {
  return axios.get(`${Base}/user/posts`, config);
}
function userPosters(config) {
  return axios.get(`${Base}/user/posters`, config);
}
function userSalesLetters(config) {
  return axios.get(`${Base}/user/sales_letters`, config);
}
function userLogos(config) {
  return axios.get(`${Base}/user/logos`, config);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userRegister,
  userLogin,
  userLogout,
  userPosts,
  userPosters,
  userSalesLetters,
  userLogos
};
